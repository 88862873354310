import React, {Component} from 'react';
import {HashRouter, Route, Switch, Redirect} from 'react-router-dom';
// import {useTypedSelector} from './redux/store';
import useAppSelector from './redux/useAppSelector';
import './scss/style.scss';
import 'spinkit/spinkit.min.css'; //TODO: move to scss
import 'react-grid-layout/css/styles.css'; //TODO: move to scss
import 'react-resizable/css/styles.css'; //TODO: move to scss
import {selectCurrentUser} from './redux/store';

const loading = (
  <div className="pt-3 text-center">
    <div className="sk-spinner sk-spinner-pulse"></div>
  </div>
);

// Containers
const TheLayout = React.lazy(() => import('./containers/TheLayout'));

// Pages
const Login = React.lazy(() => import('./views/login/Login'));

const App = () => {
  // let currentUser = useTypedSelector((state) => state.currentUser);
  let currentUser = useAppSelector(selectCurrentUser);
  return (
    <HashRouter>
      <React.Suspense fallback={loading}>
        <Switch>
          <Route exact path="/login" component={Login} />
          {/* {!currentUser && <Redirect to="/login" />} */}
          <Route path="/" component={TheLayout} />
        </Switch>
      </React.Suspense>
    </HashRouter>
  );
};

export default App;
