import {createAsyncThunk, createSlice, PayloadAction} from '@reduxjs/toolkit';
import {RootState, AppThunk} from '../store';
import Cookies from 'js-cookie';
import {useSelector, TypedUseSelectorHook} from 'react-redux';

type AppState = {
  sidebarShow: 'responsive' | boolean;
  asideShow: boolean;
  darkMode: boolean;
  currentUser: any;
};

type args = {type?: string; [key: string]: any};

const changeState = (state = initialState, {type, ...rest}: args) => {
  switch (type) {
    case 'set':
      return {...state, ...rest};
    default:
      return state;
  }
};

const initialState: AppState = {
  sidebarShow: 'responsive',
  asideShow: false,
  darkMode: false,
  currentUser: null,
};

export const appSlice = createSlice({
  name: 'app',
  initialState,
  reducers: {
    setState: (state: AppState, {type, ...rest}: args) => {
      return {...state, ...rest};
    },
    // setRelogin: (state, action) => {
    //   state.appState.reloginRequired = action.payload;
    // },
    // setAdmin: (state, action) => {
    //   state.currentUser.isAdmin = action.payload;
    // },
    // refreshToken: (state, action) => {
    //   state.currentUser = {...state.currentUser, ...action.payload.currentUser};
    //   state.appState = {...state.appState, ...action.payload.appState};
    // },
    // loginSuccess: (state, action) => {
    //   state.currentUser = {...state.currentUser, ...action.payload};
    //   state.appState = {...state.appState, isAppenticated: true, isLoggedIn: true};
    // },
  },
});

export const {setState} = appSlice.actions;

export default appSlice.reducer;
