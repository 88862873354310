import React from 'react'
import 'react-app-polyfill/ie11' // For IE 11 support
import 'react-app-polyfill/stable'
import ReactDOM from 'react-dom'
import { Provider } from 'react-redux'
import App from './App'
import { icons } from './lib/assets/icons'
import './polyfill'
import * as serviceWorker from './serviceWorker'
import store from './redux/store'
import { silentlyRefreshToken } from "./services/app/api/api"
import history from './services/app/history'

React.icons = icons

window.addEventListener('storage', function (event) {
  if (event.key == 'logout-eventIT' && event.newValue == null) {
    //("===== Got the log out event, logging out on this tab.. =====")
    store.dispatch({ type: 'set', currentUser: null })
    history.push('/login')
  }
});

async function bootstrap() {

  //if new tab was opened or page was refreshed, we want to keep the user logged in
  //the function below will check for the cookie and based on that it may refresh 
  //the token and have the user log in (silently)
  await silentlyRefreshToken(); //TODO: move to redux action (or rematch effect) 

  ReactDOM.render(
    <React.StrictMode>
      <Provider store={store}>
        <App />
      </Provider>
    </React.StrictMode>,
    document.getElementById("root")
  );

  // If you want your app to work offline and load faster, you can change
  // unregister() to register() below. Note this comes with some pitfalls.
  // Learn more about service workers: https://bit.ly/CRA-PWA
  serviceWorker.unregister()

}

bootstrap();