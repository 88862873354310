export enum DayNameShort {
  'Mon' = 'Mon',
  'Tue' = 'Tue',
  'Wed' = 'Wed',
  'Thu' = 'Thu',
  'Fri' = 'Fri',
  'Sat' = 'Sat',
  'Sun' = 'Sun',
}
export enum DayNameLong {
  'Mon' = 'Monday',
  'Tue' = 'Tuesday',
  'Wed' = 'Wednesday',
  'Thu' = 'Thursday',
  'Fri' = 'Friday',
  'Sat' = 'Saturday',
  'Sun' = 'Sunday',
}
export enum CardType {
  ScanGoal = 'ScanGoal',
  Event = 'Event',
}

export enum RenderType {
  Modal = 'Modal',
  Card = 'Card',
  Panel = 'Panel',
  Component = 'Component',
}

export enum EventType {
  ScheduleItem = 'ScheduleItem',
  Shift = 'Shift',
}

export enum ArgPlacement {
  append = 'append',
  prepend = 'prepend',
  index = 'index',
}

export enum TypedRenderProps {
  id = 'id',
  name = 'name',
  type = 'type',
  display = 'display',
  content = 'content',
  action = 'action',
  errors = 'errors',
}

export enum ErrorContentType {
  message = 'message',
  code = 'code',
  withArguments = 'withArguments',
  undefined = 'undefined',
}

export enum ContainerType {
  cardSet = 'cardSet',
  dropdown = 'dropdown',
  table = 'table',
  undefined = 'undefined',
}

export enum REQUEST_STATUS {
  SUCCESS = 'success',
  FAIL = 'fail',
  PENDING = 'pending',
  UNDEFINED = 'undefined',
}

export enum ERROR_TYPE {
  VALUE_INVALID,
  NOT_FOUND,
  DUPLICATE_ENTRY,
  PRECEEDS_RANGE,
  EXCEEDS_RANGE,
  STATE_INVALID,
  INTERVAL_INVALID,
  SELECTIONS_INVALID,
  VALUE_REQUIRED,
  BAD_REQUEST,
  NOT_PERMITTED,
}

export enum ICON_TYPE {
  P = 'PIcon',
  C = 'CIcon',
  R = 'RawSVGIcon',
}

export enum SCOPE {
  SUBLIST = 'sublist',
  SUBTABLE = 'subTable',
  BUTTON_LINK = 'buttonLink',
  BUTTON_EXPAND = 'buttonExpand',
  JSX = 'jsx',
}
export enum DataType {
  string = 'string',
  number = 'number',
  boolean = 'boolean',
  structure = 'structure',
  list = 'list',
  set = 'set',
  table = 'table',
}
