export const NEW_API_URL = process.env.REACT_APP_NEW_API_URL;
export const NEW_API_URL_CUSTOMERS = `${NEW_API_URL}/customers`;
export const NEW_API_URL_STATIONS = `${NEW_API_URL}/stations`;
export const NEW_API_URL_USERS = `${NEW_API_URL}/users`;
export const NEW_API_URL_LOCATIONS = `${NEW_API_URL}/locations`;
export const NEW_API_URL_LOCATIONS_SHORT = `${NEW_API_URL}/locations-short`;
export const NEW_API_URL_LOCATION_GROUPS = `${NEW_API_URL}/location-groups`;
export const NEW_API_URL_LOG_IN = `${NEW_API_URL}/auth/login`;
export const NEW_API_URL_REFRESH_TOKEN = `${NEW_API_URL}/auth/refresh`;
export const NEW_API_URL_GOALS_SETTING = `${NEW_API_URL}/goals`;
export const NEW_API_URL_CHANGE_MY_CURRENT_PASSWORD = `${NEW_API_URL}/auth/change-my-password`;
export const NEW_API_URL_SET_NEW_ADMIN_PASSWORD = `${NEW_API_URL}/users/admin-password`;
export const NEW_API_URL_HARDWARE = `${NEW_API_URL}/hardware`;
export const NEW_API_URL_WEEKLY_REPORTING = `${NEW_API_URL}/weekly-reporting`;
export const NEW_API_URL_LOCATION_REPORTING = `${NEW_API_URL}/location-reporting`;
export const NEW_API_URL_WEEKLY_REPORTING_MULTILEVEL = `${NEW_API_URL}/multilevel-chart`;
export const NEW_API_URL_EMPLOYEES = `${NEW_API_URL}/employees`;
export const NEW_API_URL_LOGISTICS = `${NEW_API_URL}/orders`;
export const NEW_API_URL_DEPARTMENTS = `${NEW_API_URL}/locations-departments`;

export const URL_FORMS = `${NEW_API_URL}/forms`;
