import CIcon from '@coreui/icons-react';
import {CButton} from '@coreui/react';
import React, {useRef} from 'react';
import {v4 as uuidv4} from 'uuid';
import '../../styles/colors.scss';

const componentKey = uuidv4();

export const CIconButton = (props: any) => {
  const {
    id,
    onClickAction,
    iconClass,
    buttonType,
    buttonText,
    buttonIcon,
    buttonClass,
    buttonColor,
    buttonStyle,
    buttonShape,
    buttonSize,
    buttonDisable,
    buttonVariant,
    divClassName,
    inline,
  } = props;
  console.log('Button props are: ', props);
  const onClickFunction: Function = onClickAction ? onClickAction : () => null;
  const cursor = buttonDisable ? 'not-allowed' : 'pointer';
  // console.log('Button disable status is: ', [buttonDisable, cursor]);
  const StyledCButton: React.FC = () => {
    return (
      <CButton
        key={`${componentKey}-button`}
        type={buttonType || 'button'}
        // tag={id}
        id={id}
        disabled={buttonDisable || false}
        style={{cursor: cursor}}
        className={buttonClass || 'px-4 mx-4'}
        color={buttonColor || 'primary'}
        size={buttonSize || 'sm'}
        shape={buttonShape || 'pill'}
        variant={buttonVariant || ''}
        onClick={(val: any) => onClickFunction(val)}
      >
        {buttonIcon && <CIcon key={`${componentKey}-icon`} name={buttonIcon} className={iconClass || 'mx-2'} />}
        {buttonText}
      </CButton>
    );
  };

  return inline ? (
    <div className={divClassName || 'mt-3 mb-3'}>
      <StyledCButton />
    </div>
  ) : (
    <StyledCButton />
  );
};
export default CIconButton;
