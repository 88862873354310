// import {createStore} from 'redux';
// import {useSelector, TypedUseSelectorHook} from 'react-redux';
import {configureStore, ThunkAction, Action} from '@reduxjs/toolkit';
import authenticationReducer from './contexts/auth';
import appReducer from './contexts/app';
import surveyReducer from './contexts/survey';
export const store = configureStore({
  reducer: {
    app: appReducer,
    authentication: authenticationReducer,
    survey: surveyReducer,
  },
});
export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<ReturnType, RootState, unknown, Action<string>>;

// export const selectAuth = (state: RootState) => state.authentication;
//App Selectors
export const selectApp = (state: RootState) => state.app;
export const selectCurrentUser = (state: RootState) => state.app.currentUser;

//Questionnaire Selectors
export const selectSurvey = (state: RootState) => state.survey;
export const selectSurveyModal = (state: RootState) => state.survey.modalState;
export const selectSurveyForm = (state: RootState) => state.survey.surveyForm;

export default store;

// middleware: (getDefaultMiddleware) =>
//   getDefaultMiddleware({
//     serializableCheck: {
//       // Ignore these action types
//       //     ignoredActions: [FILTERS.SET_SELECTIONS],
//       ignoreState: true,
//     },
//   }),
