import {createAsyncThunk, createSlice, PayloadAction} from '@reduxjs/toolkit';
import {RootState, AppThunk} from '../store';
import Cookies from 'js-cookie';
import {useSelector, TypedUseSelectorHook} from 'react-redux';

type AuthState = {
  sidebarShow: 'responsive' | boolean;
  asideShow: boolean;
  darkMode: boolean;
  currentUser: any;
};
const initialState: AuthState = {
  sidebarShow: 'responsive',
  asideShow: false,
  darkMode: false,
  currentUser: null,
};

export const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    setUser: (state, action) => {
      state.currentUser = action.payload;
    },
    // setRelogin: (state, action) => {
    //   state.authState.reloginRequired = action.payload;
    // },
    // setAdmin: (state, action) => {
    //   state.currentUser.isAdmin = action.payload;
    // },
    // refreshToken: (state, action) => {
    //   state.currentUser = {...state.currentUser, ...action.payload.currentUser};
    //   state.authState = {...state.authState, ...action.payload.authState};
    // },
    // loginSuccess: (state, action) => {
    //   state.currentUser = {...state.currentUser, ...action.payload};
    //   state.authState = {...state.authState, isAuthenticated: true, isLoggedIn: true};
    // },
  },
});

export const {setUser} = authSlice.actions;

export default authSlice.reducer;
