import Cookies from 'js-cookie';
import moment from 'moment';
import wretch from 'wretch';
import store from '../../../redux/store';
import {NEW_API_URL_REFRESH_TOKEN} from '../constants';
import history from '../history';
import {unzipb64Payload} from '../../../@webapp-lib/pathspot-react';
const controller = new AbortController();

export const abortController = controller;

const w = wretch()
  .content('application/json')
  .catcher('Error', (err) => {
    console.log('Error occured: ', err);
    throw err;
  })
  .catcher('TypeError', (err) => {
    console.log('TypeError occured: ', err);
    throw err;
  })
  .catcher(401, (err) => {
    console.log('401 Error occured: ', err);
    throw err;
  })
  .catcher(500, (err) => {
    console.log('500 Error occured: ', err);
    throw err;
  });

export const userLogOut = () => {
  Cookies.remove('userLoggedInIT');
  localStorage.removeItem('logout-eventIT');
  store.dispatch({type: 'set', currentUser: null});
  history.push('/login');
};

export const silentlyRefreshToken = async () => {
  let reLogInNeeded = true;
  try {
    console.log('looking for a cookie:');
    if (Cookies.get('userLoggedInIT')) {
      console.log('===== Found the cookie. Trying to refresh the token.. =====');
      const silentLogInResponse = await api.noAuth().url(`${NEW_API_URL_REFRESH_TOKEN}`).options({credentials: 'include', mode: 'cors'}).post().json();
      if (silentLogInResponse && silentLogInResponse.accessToken) {
        const {userId, userEmail, accessToken, accessTokenExpMs, sidebarItems} = silentLogInResponse;
        api.setToken(accessToken);
        Cookies.set('userLoggedInIT', 'true');
        localStorage.setItem('logout-eventIT', 'logout' + Math.random());
        store.dispatch({
          type: 'app/setState',
          currentUser: {email: userEmail},
        });

        setTimeout(() => silentlyRefreshToken(), Math.floor(accessTokenExpMs / 2));
        reLogInNeeded = false;
        console.log('===== refresh was successful, no re-login needed =====');
      }
    }
  } catch (err) {
  } finally {
    if (reLogInNeeded) {
      console.log('Re-login needed');
      userLogOut();
    }
  }
};

// export let api = {
//   token: '',

//   getToken: function () {
//     return this.token;
//   },

//   setToken: function (token: string) {
//     this.token = token;
//   },

//   noAuth: function () {
//     return w;
//   },

//   withAuth: function () {
//     return w.auth(this.token);
//   },
// };
export const api = {
  token: '',
  abortController: new AbortController(),

  setToken: function (token: string) {
    this.token = token;
  },

  noAuth: function () {
    return w;
  },

  withAuth: function () {
    // console.log('Using with auth...', this);
    return w.auth(this.token).signal(this.abortController);
  },

  withAuthAndNoSignal: function () {
    return w.auth(this.token);
  },

  abortAllRequests: function () {
    this.abortController.abort();
    this.abortController = new AbortController(); //new controller needs to be set up to abort future requests
  },
  zjson: function (json: any) {
    console.log('12345678910111213141516------- json: ', json);
    console.log('json.data', json.data);
    const unzippedData = unzipb64Payload(json.data);
    console.log('json.unizipped payload', unzippedData);
    //console.log('12345678910111213141516+++++++ In wretcher process.env.REACT_APP_API_URL: ', process.env);
    return json && json.data ? {data: unzippedData ? unzippedData : json.data} : json;
  },
};
