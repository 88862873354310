import { DropdownItem, FlexTableField} from '../../../@webapp-lib/pathspot-react';
import {api} from '../../../services/app/api/api';
import {URL_FORMS} from '../../../services/app/constants';
import {SCOPE, DataType} from '../../../@webapp-lib/pathspot-react';
//TODO: Remove cutomer 1 as equal to public.
export enum SurveyContext {
  NEW = 'new',
  EDITING = 'editing',
  VIEWING = 'viewing',
  REPLICATING = 'replicating',
  UNKNOWN = 'unknown',
}
// export enum RequestStatusType {
//   CREATE_NEW_FORM = 'createNewForm',
//   ASSIGN_FORM = 'assignForm'

// }
export enum SurveyScope {
  PRIVATE = 'private',
  PUBLIC = 'public',
  UNTOUCHED = 'untouched',
  UNKNOWN = 'unknown',
}
export enum SURVEY_FIELDNAME {
  TOGGL = 'surveyEnable',
  OBJECT = 'survey',
}
export enum SVFIELD {
  QMODEL = 'questionModel',
  AMODEL = 'answerModel',
}
export enum QuestionType {
  RADIO = 'radio',
  BOOLEAN = 'boolean',
  MULTISELECT = 'multi_select',
  FREETEXT = 'free_text',
}
export enum QuestionTypeLabel {
  RADIO = 'Radio (User picks one answer from one or more options)',
  BOOLEAN = 'Boolean (User picks true/false, typically presented as Yes/No)',
  MULTISELECT = 'Multi-Select (User picks one or more answers from one or more options)',
  FREETEXT = 'Free-Text (User can type an answer)',
}
export const QuestionTypeOptions = [
  {label: QuestionTypeLabel.RADIO, value: QuestionType.RADIO},
  {label: QuestionTypeLabel.BOOLEAN, value: QuestionType.BOOLEAN},
  {label: QuestionTypeLabel.MULTISELECT, value: QuestionType.MULTISELECT},
  {label: QuestionTypeLabel.FREETEXT, value: QuestionType.FREETEXT},
];
export enum RequestType {
  CREATE_NEW_FROM = 'createNewForm',
  ASSIGN_FORM = 'assignForm',
}

export enum AnswerType {
  TRUE = 'true',
  FALSE = 'false',
  EMPTY = '-',
}
type AnswerIndexSignature = {
  [key: string]: string;
};
export const AnswerTypeLabels: AnswerIndexSignature = {
  [AnswerType.TRUE]: 'True (this answer is considered correct/passing)',
  [AnswerType.FALSE]: 'False (this answer is considered incorrect/failing)',
  [AnswerType.EMPTY]: 'Free (there is no right or wrong answer)',
};
type AnswerTypeValueSignature = {
  [key: string]: any;
};
export const AnswerTypeDBValues: AnswerTypeValueSignature = {
  [AnswerType.TRUE]: true,
  [AnswerType.FALSE]: false,
  [AnswerType.EMPTY]: null,
};
export const AnswerTypeOptions = Object.keys(AnswerTypeLabels)?.map((key: string) => {
  return {
    label: AnswerTypeLabels[key],
    value: key,
  };
});

export interface SurveyAssignment {
  locationGroups?: DropdownItem[];
  locations?: DropdownItem[];
  departments?: DropdownItem[];
}

export interface AssignedSurvey {
  id: number;
  formId: number;
  userIdMode?: string;
  launchOnScan?: boolean;
  endsWithScan?: boolean;
  asignees?: SurveyAssignment[];
}

export interface Customer {
  id: number | string;
  name?: string;
  menu?: DropdownItem;
}

export interface QuestionModel {
  id?: number | null | undefined;
  idx?: number;
  scope?: SurveyScope;
  customer?: Customer;
  questionType?: QuestionType;
  questionText?: QuestionAnswerModelString;
  answers?: AnswerModel[];
  menu?: DropdownItem;
}

export interface AnswerModel {
  answerText?: QuestionAnswerModelString | undefined;
  answerTruth?: boolean | null;
}

export interface SurveyForm {
  formId?: number;
  formScope?: SurveyScope;
  customer?: Customer;
  title?: string;
  acceptableMessage?: string;
  unacceptableMessage?: string;
  questions?: QuestionModel[];
  asignees?: SurveyAssignment;
}

export interface AssignedSurveyPayload extends AssignedSurvey {
  id: number;
  formId: number;
  userIdMode: string;
  launchOnScan: boolean;
  hasFormDetails: boolean;
}
export interface AssignedSurveysPayload {
  departmentForms: {['deptId']: AssignedSurveyPayload};
  locationForms: {['locationId']: AssignedSurveyPayload};
}
export interface QuestionAnswerModelString {
  id: number;
  customerId?: number | null | undefined;
  en: string;
  es?: string;
}

export const useExistingButtonProps = {
  buttonType: 'button',
  buttonText: 'Use Existing Question',
  buttonIcon: 'cil-reload',
  buttonClass: 'mx-2 pr-4',
  buttonColor: 'primary',
  buttonShape: 'standard',
  buttonSize: 'sm',
};
export const startNewButtonProps = {
  buttonType: 'button',
  buttonText: 'Start New',
  buttonIcon: 'cil-playlist-add',
  buttonClass: 'mx-2 pr-4',
  buttonColor: 'primary',
  buttonShape: 'standard',
  buttonSize: 'sm',
};

export const wellnessCheckInfoTable: FlexTableField[] = [
  {header: 'ID', key: 'id', style: {width: '5%'}},
  {header: 'Customer', key: 'customer', style: {width: '16%'}},
  {header: 'Type', key: 'questionType', style: {width: '8%'}},
  {header: 'Question Text', key: 'questionText', style: {width: '30%'}},
  {
    header: 'Answer ID',
    key: 'answers',
    style: {width: '8%'},
    scoped: true,
    scope: {
      type: SCOPE.SUBTABLE,
      attributes: {
        fields: [{key: 'answerTextId', style: {width: '100%'}, dataType: DataType.number}],
      },
    },
  },
  {
    header: 'Answer Text',
    key: 'answers',
    style: {width: '22%'},
    scoped: true,
    scope: {
      type: SCOPE.SUBTABLE,
      attributes: {
        fields: [{key: 'answerText', style: {width: '100%'}}],
      },
    },
  },
  {
    header: 'Answer Truth',
    key: 'answers',
    style: {width: '12%'},
    scoped: true,
    scope: {
      type: SCOPE.SUBTABLE,
      attributes: {
        fields: [{key: 'answerTruth', style: {width: '100%'}}],
      },
    },
  },
];

//Form Assignment----------------------------------------------------------
export const getAllWellnessCheckForms = async () => {
  return await api.withAuth().url(`${URL_FORMS}?all_forms=true`).get().json();
};
export const getWellnessCheckFormsFiltered = async ({...values}) => {
  const requestString = buildViewLink({...values});
  // console.log('Request string from options is: ', requestString);
  const endpointString = requestString.split('view')[1];
  // console.log('Endpoint string is: ', endpointString);
  return await api.withAuth().url(`${URL_FORMS}${endpointString}`).get().json();
};
export const createWellnessCheckForm = async ({...values}) => {
  return await api
    .withAuth()
    .url(`${URL_FORMS}`)
    .post({...values})
    .json();
};

//Question Models-----------------------------------------------------------
export const getWellnessCheckQuestionModels = async ({...values}) => {
  const requestString = buildQuestionModelLink({...values});
  const endpointString = requestString.split('forms')[1];
  return await api.withAuth().url(`${URL_FORMS}${endpointString}`).get().json();
};

export const createWellnessCheckQuestionModel = async ({...values}) => {
  return await api
    .withAuth()
    .url(`${URL_FORMS}/questions`)
    .post({...values})
    .json();
};

//Answer Text----------------------------------------------------------------
export const getWellnessCheckAnswers = async () => {
  return await api.withAuth().url(`${URL_FORMS}/text/answer`).get().json();
};
//Question Text--------------------------------------------------------------
export const getWellnessCheckQuestions = async () => {
  return await api.withAuth().url(`${URL_FORMS}/text/questions`).get().json();
};
//Assigned Forms-------------------------------------------------------------
export const getWellnessCheckAssignedForms = async ({...values}) => {
  if (!values.locationId && !values.departmentId) {
    return await api.withAuth().url(`${URL_FORMS}/assigned_forms`).get().json();
  }
  const requestString = buildAssignedFormsLink({...values});
  console.log('Request string from buildAssignedFormsLink is: ', requestString);
  return await api.withAuth().url(`${URL_FORMS}${requestString}`).get().json();
};
export const getAssignableLocationAssets = async (customerId: any = undefined) => {
  return await api.withAuth().url(`${URL_FORMS}/location-assets`).post({customerId}).json();
};
export const assignWellnessCheckForm = async ({...values}) => {
  return await api
    .withAuth()
    .url(`${URL_FORMS}/assigned_forms`)
    .post({...values})
    .json();
};
//Common/Helper Methods------------------------------------------------------------
export const buildViewLink = (item: any) => {
  const customerString = item && item.customerId && item.customerId !== null && item.customerId !== '-' ? `customer_id=${item.customerId}` : 'customer_id=null';
  const detailedString = item && item.detailed ? `detailed=true` : `detailed=false`;
  const formString = item && item.id ? `&form_id=${item.id}` : item && item.formId ? `&form_id=${item.formId}` : '';
  const returnString = `forms/view?${detailedString}&${customerString}${formString}`;
  // console.log('Return string in build from link is: ', returnString);
  return returnString;
};
export const buildAssignedFormsLink = (values: any) => {
  const locationString = values && values.locationId && values.locationId !== null && values.locationId !== '-' ? `location_id=${values.locationId}` : 'location_id=null';
  const detailedString = values && values.detailed ? `detailed=true` : `detailed=false`;
  const departmentString =
    values && values.departmentId && values.departmentId !== null && values.departmentId !== '-' ? `department_id=${values.departmentId}` : 'department_id=null';
  const returnString = `/assigned_forms?${detailedString}&${locationString}&${departmentString}`;
  // console.log('Return string in assigned forms link is: ', returnString);
  return returnString;
};
export const buildReplicaLink = (item: any) => {
  const customerString = item && item.customerId && item.customerId !== null && item.customerId !== '-' ? `customer_id=${item.customerId}` : 'customer_id=null';
  const detailedString = item && item.detailed ? `detailed=true` : `detailed=false`;
  const formString = item && item.id ? `&form_id=${item.id}` : item && item.formId ? `&form_id=${item.formId}` : '';
  const returnString = `forms/new/rebase?${detailedString}&${customerString}${formString}`;
  // console.log('Return string in build from link is: ', returnString);
  return returnString;
};
export const buildQuestionModelLink = (item: any) => {
  const customerString = item && item.customerId && item.customerId !== null && item.customerId !== '-' ? `customer_id=${item.customerId}` : 'customer_id=null';
  const detailedString = item && item.detailed ? `detailed=true` : `detailed=false`;
  const questionString = item && item.questionId ? (item && item.customerId ? `&question_id=${item.questionId}` : `question_id=${item.questionId}`) : '';
  const returnString = `forms/questions?${detailedString}&${customerString}${questionString}`;
  // console.log('Return string in build from link is: ', returnString);
  return returnString;
};
export const getAssignedLocationsDisplayText = (assignmentData: any, form: any) => { // make sure there arent any repeats
  let seenLocationIds: any = {};
  const assignedLocations = assignmentData.locationForms
    .filter((asignee: any) => (asignee.formId == (form?.id || form?.formId)))
    ?.map((assignedLocation: any) => {
      return {...assignedLocation, displayName: `${assignedLocation.locationId} - ${assignedLocation.locationName}`};
    });
  const assignedLocationsStr = assignedLocations.map((location: any) => {
    if(!seenLocationIds[location?.locationId] && location.displayName){
      seenLocationIds[location?.locationId] = true;
      return location.displayName;
    }
  }).join(', ');
  const displayLocationStr = assignedLocationsStr.endsWith(', ') ? assignedLocationsStr.slice(0, -1) : assignedLocationsStr;
  // console.log('Assigned locations are: ', assignedLocations);
  return displayLocationStr;
};
export const getAssignedDepartmentDisplayText = (assignmentData: any, form: any) => {
  let seenDepartmentIds: any = {};
  const assignedDepartments = assignmentData.departmentForms
    .filter((asignee: any) => asignee.formId === (form?.id || form?.formId))
    ?.map((assignedDepartment: any) => {
      return {...assignedDepartment, displayName: `${assignedDepartment.departmentId} - ${assignedDepartment.departmentName}`};
    });
  const assignedDepartmentsStr = assignedDepartments.map((department: any) => {
    if(!seenDepartmentIds[department.departmentId] && department.displayName){
      seenDepartmentIds[department.departmentId] = true;
      return department.displayName;
    }
  }).join(', ');
  const displayDepartmentStr = assignedDepartmentsStr.endsWith(', ') ? assignedDepartmentsStr.slice(0, -1) : assignedDepartmentsStr;
  // console.log('Assigned departments are: ', assignedDepartments);
  return displayDepartmentStr;
};
export const addFormAssignmentInfo = (assignmentData: any, form: any) => {
  const displayLocationStr = getAssignedLocationsDisplayText(assignmentData, form);
  const displayDepartmentStr = getAssignedDepartmentDisplayText(assignmentData, form);
  const newForm = {...form, customer: `${form.customerId} - ${form.customerName}`, assignedLocations: displayLocationStr, assignedDepartments: displayDepartmentStr};
  return newForm;
};

export const getCustomerFromListing = (qModel: any, customerList: any) => {
  return customerList ? customerList.find((customer: any) => customer.value === qModel.customerId) : {label: '-', value: null};
};

export const createTableFormatQuestions = (question: any, values: any, data: any = undefined) => {
  console.log('Values in create table format questions are: ', values);
  console.log('Customer should be: ', `${values['customer']?.label.substring(0, 16)}...`);
  console.log('Question to be converted is: ', question);
  const customerDisplayStr = question?.customer?.label
    ? question.customer.label
    : question?.customer?.id && data?.customerListing
    ? getCustomerFromListing(question, data.customerListing)?.label
    : '-';
  return {
    // customer: `${values['customer']?.label?.substring(0, 16)}...` || '-',
    id: question.questionText.id,
    customer: customerDisplayStr?.substring(0, 16),
    questionType: question.questionType,
    questionText: question.questionText.en || '',
    answers: question.answers.map((answer: any) => {
      return {
        answerTextId: answer.answerText.id,
        answerText: answer.answerText.en,
        answerTruth: answer.answerTruth,
      };
    }),
  };
};
